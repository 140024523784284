// App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import LearnMore from './LearnMore';
import MainPage from './MainPage'; // Import MainPage from the separate file
import './styles.css'; // Import the external CSS file
import Media from './Media';
import Portfolio from './Portfolio';
const App = () => {
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<MainPage />} />
        <Route path="/learn-more" element={<LearnMore />} />
        <Route path="/media" element={<Media />} />
        <Route path="/portfolio" element={<Portfolio />} />
      </Routes>
    </Router>
  );
};

export default App;
