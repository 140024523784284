import React, { useEffect, useState, useRef } from 'react';
import { Container, Row, Col, Card, Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import CustomNavbar from './Navbar'; // Import the CustomNavbar
import './styles.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';

const MainPage = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [showMobilePopup, setShowMobilePopup] = useState(false);
  const [showWebPopup, setShowWebPopup] = useState(false);
  const [latestArticles, setLatestArticles] = useState([]);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 992);
    };

    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    const fetchMediumArticles = async () => {
      try {
        const response = await fetch('https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@nika.skeba');
        const data = await response.json();
        const articles = data.items.slice(0, 3).map(item => {
          // Extract the image URL from the description
          const regex = /<img.*?src="(.*?)"/;
          const match = regex.exec(item.description);
          const thumbnail = match ? match[1] : 'default-image-url.jpg'; // Use a default image if none is found

          return {
            title: item.title,
            link: item.link,
            thumbnail: thumbnail,
          };
        });
        setLatestArticles(articles);
      } catch (error) {
        console.error("Failed to fetch Medium articles", error);
      }
    };

    fetchMediumArticles();

    window.addEventListener('resize', handleResize);
    document.addEventListener('mousedown', handleClickOutside);
    handleResize(); // Initial check

    return () => {
      window.removeEventListener('resize', handleResize);
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const closeDropdown = () => {
    setIsDropdownOpen(false);
  };

  const handleShowMobilePopup = () => {
    setShowMobilePopup(true);
  };

  const handleCloseMobilePopup = () => {
    setShowMobilePopup(false);
  };

  const handleShowWebPopup = () => {
    setShowWebPopup(true);
  };

  const handleCloseWebPopup = () => {
    setShowWebPopup(false);
  };

  return (
    <div className="main-container">
      <Container fluid className="styled-container text-white p-4 border border-white border-5 mt-5">
        <CustomNavbar 
          handleShowWebPopup={handleShowWebPopup} 
          handleShowMobilePopup={handleShowMobilePopup} 
          isMobile={isMobile} 
          isDropdownOpen={isDropdownOpen} 
          toggleDropdown={toggleDropdown} 
          closeDropdown={closeDropdown} 
          dropdownRef={dropdownRef} 
        />
        
        <Row className="align-items-center my-4 text-end">
          <Col md={5}>
            <h1 className="styled-h1">
              NICHOLAS <br /> SKEBA
            </h1>
            <p style={{ color: '#262626'}}>PHP / Python / JS <br /> AI Focused</p>
            <div className="button-container">
              <Link to="/learn-more">
                <button className="styled-button">
                  <span className="left"></span>
                  <span className="right">Learn More</span>
                </button>
              </Link>
            </div>
          </Col>
          <Col md={7} style={{ position: 'relative' }}>
            <img src="path-to-your-image/image.png" alt="Main" className="img-fluid" />
            <DigitalClock />
          </Col>
        </Row>

        <Row>
          <Col md={4} className="mb-3">
            <Card className="styled-image-card text-white">
              <div className="image-container" style={{ backgroundImage: 'url(path-to-your-image/image1.png)' }}></div>
              <Card.Body className="card-content">
                <Card.Title className="card-title">Web Development</Card.Title>
                <Link to="/portfolio">
                  <button className="styled-button">
                    <span className="left"></span>
                    <span className="right">View Projects</span>
                  </button>
                </Link>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4} className="mb-3">
            <Card className="styled-image-card text-white">
              <div className="image-container" style={{ backgroundImage: 'url(path-to-your-image/image2.png)' }}></div>
              <Card.Body className="card-content">
                <Card.Title className="card-title">Mobile Apps</Card.Title>
                <Link to="/portfolio">
                  <button className="styled-button">
                    <span className="left"></span>
                    <span className="right">View Projects</span>
                  </button>
                </Link>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4} className="mb-3">
            <Card className="styled-image-card text-white">
              <div className="image-container" style={{ backgroundImage: 'url(path-to-your-image/image3.png)' }}></div>
              <Card.Body className="card-content">
                <Card.Title className="card-title">Media and Design</Card.Title>
                <Link to="/media">
                  <button className="styled-button">
                    <span className="left"></span>
                    <span className="right">View Projects</span>
                  </button>
                </Link>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <Row className="align-items-center" style={{ marginTop: '30px' }}>
          <Col md={12}>
            <h2 style={{ color: '#ff6b6b', textAlign: 'left' }}>Professional Summary</h2>
          </Col>
        </Row>

        <Row className="align-items-center">
          <Col md={3}>
            <img src="path-to-your-image/profilephoto.png" alt="Profile" className="img-fluid" />
          </Col>
          <Col md={9}>
            <p style={{ color: 'black', marginBottom: '30px' }}>
              In my professional journey, I have honed my expertise in client journey optimization, data-driven strategies, and cross-functional collaboration, making me a specialist in Lifecycle Marketing Management. I am skilled in conducting A/B testing, creating insightful dashboards, and utilizing a range of software tools to drive marketing growth. My software skills are extensive, including Salesforce Marketing Cloud, Marketo, Google Analytics, Tableau, Optimizely, and Mailchimp.
            </p>
            <p style={{ color: 'black' }}>
              I also possess a strong foundation in coding, with proficiency in Python, HTML, CSS, JavaScript, PHP, Large Language Models (LLM), and SQL. This combination of marketing savvy and technical expertise significantly enhances my capabilities. Additionally, I am multilingual, with advanced proficiency in German and Spanish and native fluency in English, which has been invaluable in my international business dealings.
            </p>
          </Col>
        </Row>

        {/* Medium RSS Feed Section */}
        <Card className="styled-card text-white mt-4">
          <Card.Body>
            <h2>Latest Articles</h2>
            <Row>
              {latestArticles.map((article, index) => (
                <Col md={4} key={index}>
                  <div className="medium-article-wrapper">
                    <a href={article.link} target="_blank" rel="noopener noreferrer">
                      <img 
                        src={article.thumbnail} 
                        alt={article.title} 
                        style={{ width: '250px', height: '150px', objectFit: 'cover' }} 
                        className="img-fluid" 
                      />
                    </a>
                    <p style={{ color: 'white', fontWeight: 'bold', marginTop: '10px' }}>{article.title}</p>
                  </div>
                </Col>
              ))}
            </Row>
            <div className=" mt-4">
              <a href="https://medium.com/@nika.skeba" target="_blank" rel="noopener noreferrer">
                <button className="styled-button">
                  <span className="left"></span>
                  <span className="right">View More</span>
                </button>
              </a>
            </div>
          </Card.Body>
        </Card>
      </Container>

      <Modal show={showMobilePopup} onHide={handleCloseMobilePopup} centered>
        <Modal.Body>
          <h2 style={{ color: 'rgb(255, 107, 107)' }}>Mobile Apps</h2>
          <div className="app-box">
            <div className="app-item">
              <img src="path-to-your-image/appimage1.png" alt="App 1" className="app-icon" />
              <div className="app-details">
                <div className="app-title">Task Pads - AI Task Manager</div>
                <div className="app-subtitle">com.taskpads.app</div>
              </div>
            </div>
            <div className="app-item">
              <img src="path-to-your-image/appimage2.png" alt="App 2" className="app-icon" />
              <div className="app-details">
                <div className="app-title">Big Burger Co</div>
                <div className="app-subtitle">com.bigburgerco.app</div>
              </div>
            </div>
            <div className="app-item">
              <img src="path-to-your-image/appimage3.png" alt="App 3" className="app-icon" />
              <div className="app-details">
                <div className="app-title">CurbAware - Free Curbside App</div>
                <div className="app-subtitle">com.curbaware.app</div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal show={showWebPopup} onHide={handleCloseWebPopup} centered>
        <Modal.Body>
          <h2 style={{ color: 'rgb(255, 107, 107)' }}>Web Development</h2>
          <div className="app-box">
            <div className="app-item">
              <img src="path-to-your-image/webimage1.png" alt="Web App 1" className="app-icon" />
              <div className="app-details">
                <div className="app-title">Costbrain</div>
                <div className="app-subtitle">Restaurant food costing software</div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

const DigitalClock = () => {
  const [time, setTime] = useState(new Date().toLocaleTimeString('en-GB', { hour12: false }));

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTime(new Date().toLocaleTimeString('en-GB', { hour12: false }));
    }, 1000);
    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className="clock-container">
      <div className="dimmed-eight">88:88:88</div>
      <div className="time">{time}</div>
    </div>
  );
};

export default MainPage;
