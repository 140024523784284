import React, { useState, useRef, useEffect } from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './styles.css'; // Ensure this contains all necessary styles
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';

const CustomNavbar = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 992);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  const handleResize = () => {
    setIsMobile(window.innerWidth < 992);
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const closeDropdown = () => {
    setIsDropdownOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        closeDropdown();
      }
    };

    if (isDropdownOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isDropdownOpen]);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <Navbar expand="lg" variant="dark" className="styled-navbar">
      <Navbar.Brand className="styled-brand">
        <div className="logo-container">
          <div className="logo-box">
            <div className="logo">NS</div>
          </div>
        </div>
      </Navbar.Brand>
      {isMobile ? (
        <>
          <div className="mobile-menu-button" onClick={toggleDropdown}>
            <i className="fas fa-bars"></i>
          </div>
          {isDropdownOpen && (
            <div className="mobile-dropdown-menu" ref={dropdownRef}>
    <div className="close-button" onClick={closeDropdown}>
      <i className="fas fa-times"></i> {/* FontAwesome icon for close */}
    </div>
    <Nav.Link as={Link} to="/" className="nav-item" onClick={closeDropdown}>
      Home
    </Nav.Link>
    <Nav.Link as={Link} to="/learn-more" className="nav-item" onClick={closeDropdown}>
      Learn More
    </Nav.Link>
    <Nav.Link as={Link} to="/portfolio" className="nav-item" onClick={closeDropdown}>
      Portfolio
    </Nav.Link>
    <Nav.Link as={Link} to="/media" className="nav-item" onClick={closeDropdown}>
      Media
    </Nav.Link>
    <Nav.Link
      href="https://www.facebook.com/nskeba/"
      target="_blank"
      className="nav-item"
      onClick={closeDropdown}
    >
      <i className="fab fa-facebook-f"></i>
    </Nav.Link>
    <Nav.Link
      href="https://www.youtube.com/@Official_AI_Zone"
      target="_blank"
      className="nav-item"
      onClick={closeDropdown}
    >
      <i className="fab fa-youtube"></i>
    </Nav.Link>
    <Nav.Link
      href="https://www.linkedin.com/in/nicholas-skeba/"
      target="_blank"
      className="nav-item"
      onClick={closeDropdown}
    >
      <i className="fab fa-linkedin"></i>
    </Nav.Link>
  </div>
          )}
        </>
      ) : (
        <>
          <Nav className="mr-auto">
            <Nav.Link as={Link} to="/" className="nav-item">
              Home
            </Nav.Link>
            <Nav.Link as={Link} to="/learn-more" className="nav-item">
              Learn More
            </Nav.Link>
            <Nav.Link as={Link} to="/portfolio" className="nav-item">
              Portfolio
            </Nav.Link>
            <Nav.Link as={Link} to="/media" className="nav-item">
              Media
            </Nav.Link>
          </Nav>
          <Nav className="ml-auto">
            <Nav.Link
              href="https://www.facebook.com/nskeba/"
              target="_blank"
              className="social-nav-link"
            >
              <i className="fab fa-facebook-f"></i>
            </Nav.Link>
            <Nav.Link
              href="https://www.youtube.com/@Official_AI_Zone"
              target="_blank"
              className="social-nav-link"
            >
              <i className="fab fa-youtube"></i>
            </Nav.Link>
            <Nav.Link
              href="https://www.linkedin.com/in/nicholas-skeba/"
              target="_blank"
              className="social-nav-link"
            >
              <i className="fab fa-linkedin"></i>
            </Nav.Link>
          </Nav>
        </>
      )}
    </Navbar>
  );
};

export default CustomNavbar;
