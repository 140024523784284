import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';

const SoundCloudWidgetPlayer = ({ url, width, height, options }) => {
  const iframeRef = useRef(null);

  useEffect(() => {
    const widget = window.SC.Widget(iframeRef.current);
    widget.bind(window.SC.Widget.Events.READY, () => {
      console.log('SoundCloud Widget is ready');
    });

    return () => {
      widget.unbind(window.SC.Widget.Events.READY);
    };
  }, [])

  return (
    <IframeContainer>
      <iframe
        ref={iframeRef}
        title="SoundCloud Player"
        width={width}
        height={height}
        scrolling="no"
        frameBorder="no"
        src={`https://w.soundcloud.com/player/?url=${url}&${new URLSearchParams(options).toString()}`}
      ></iframe>
    </IframeContainer>
  );
};

const IframeContainer = styled.div`
  max-width: 550px;

  margin: 20px 0;
  border: 15px solid #fff;  /* Customize border color */
  border-radius: 10px;  /* Customize border radius */
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);  /* Customize shadow */

  iframe {
    display: block;
    width: 550px;
    height: ${props => props.height || '266px'};  /* Customize iframe height */
  }
`;

export default SoundCloudWidgetPlayer;
