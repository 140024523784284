import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import CustomNavbar from './Navbar'; // Import the CustomNavbar
import './styles.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';

const Portfolio = () => {
  return (
    <div className="main-container">
      <Container fluid className="styled-container text-white p-4 border border-white border-5 mt-5">
        <CustomNavbar />

        {/* Add space between the top navbar and portfolio section */}
        <div style={{ marginTop: '50px' }}></div>

        {/* Coding Section */}
        <Row>
          <Col md={12}>
            {/* Make Portfolio h2 color black */}
            <h2 className="section-title" style={{ color: 'black' }}>Portfolio</h2>
            <Row>
              {/* AI Email Responder */}
              <Col md={12} className="mb-4">
                <div className="project-box">
                  <Row>
                    <Col md={2}>
                      <a href="https://github.com/nikaskeba/Ollama-LM-Studio-GPT-Gmail-Summarize-and-AI-Email-Writer" target="_blank" rel="noopener noreferrer">
                        <img src="path-to-your-image/email-image.png" alt="AI Email Responder" className="project-image" />
                      </a>
                    </Col>
                    <Col md={10}>
                      <h3 className="project-title">
                        <a href="https://github.com/nikaskeba/Ollama-LM-Studio-GPT-Gmail-Summarize-and-AI-Email-Writer" className="project-link" target="_blank" rel="noopener noreferrer">
                          AI Email Responder
                        </a>
                      </h3>
                      <p className="project-description">
                        This Flask-based web application allows users to fetch emails, summarize their content using a Language Learning Model (LLM), and respond to them directly from the interface.
                      <br />
                      <span style={{ color: '#808080', fontSize: '0.9em' }}>
                        Flask, HTML, SMTP, MIMEText, BeautifulSoup, Regex, REST API, Python, JSON, LLM, Gmail API
                      </span>
                      </p>
                    </Col>
                  </Row>
                </div>
              </Col>

              {/* AI Task Creator */}
              <Col md={12} className="mb-4">
                <div className="project-box">
                  <Row>
                    <Col md={2}>
                      <a href="https://taskpads.com/" target="_blank" rel="noopener noreferrer">
                        <img src="path-to-your-image/taskpads-image.png" alt="AI Task Creator" className="project-image" />
                      </a>
                    </Col>
                    <Col md={10}>
                      <h3 className="project-title">
                        <a href="https://taskpads.com/" className="project-link" target="_blank" rel="noopener noreferrer">AI Task Creator</a>
                      </h3>
                      <p className="project-description">
                        TaskPads lets you effortlessly create recipes, tasks, and notes, all shareable with others. Experience AI-powered quick task creation to boost your productivity.
                      <br />
                      <span style={{ color: '#808080', fontSize: '0.9em' }}>
                        React, React Native, AsyncStorage, Auth0, JavaScript, HTML, CSS, Flask, Navigation, Router, REST API
                      </span>
                      </p>
                    </Col>
                  </Row>
                </div>
              </Col>

              {/* AI Image Generator */}
              <Col md={12} className="mb-4">
                <div className="project-box">
                  <Row>
                    <Col md={2}>
                      <a href="https://imagetoolai.netlify.app/" target="_blank" rel="noopener noreferrer">
                        <img src="path-to-your-image/imagetool-image.png" alt="AI Image Generator" className="project-image" />
                      </a>
                    </Col>
                    <Col md={10}>
                      <h3 className="project-title">
                        <a href="https://imagetoolai.netlify.app/" className="project-link" target="_blank" rel="noopener noreferrer">AI Image Generator</a>
                      </h3>
                      <p className="project-description">
                        Image app with credit system that charges per generation. Allows a user to enter their own Octopi key for generations using their own AI key.
                      <br />
                      <span style={{ color: '#808080', fontSize: '0.9em' }}>
                        React, Konva, Axios, Bootstrap, JavaScript, REST API, HTML5 Canvas, CSS, Local Storage, Python
                      </span>
                      </p>
                    </Col>
                  </Row>
                </div>
              </Col>

              {/* Restaurant Invoice Software */}
              <Col md={12} className="mb-4">
                <div className="project-box">
                  <Row>
                    <Col md={2}>
                      <a href="https://costbrain.com/" target="_blank" rel="noopener noreferrer">
                        <img src="path-to-your-image/costbrain-image.png" alt="Restaurant Invoice Software" className="project-image" />
                      </a>
                    </Col>
                    <Col md={10}>
                      <h3 className="project-title">
                        <a href="https://costbrain.com/" className="project-link" target="_blank" rel="noopener noreferrer">Restaurant Invoice Software</a>
                      </h3>
                      <p className="project-description">
                        CostBrain is designed for restaurants, chefs, and owners to track inventory costs across multiple suppliers effortlessly. It allows you to upload invoices, which are automatically parsed into data to help manage and track costs among your ingredients.
                      <br />
                      <span style={{ color: '#808080', fontSize: '0.9em' }}>
                        React, React Router, JavaScript, HTML, CSS, FontAwesome, REST API, PHP, MySQL, Bootstrap, JSON, Environment Variables, CRUD operations (Create, Read, Update, Delete)
                      </span>
                      </p>
                    </Col>
                  </Row>
                </div>
              </Col>

            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Portfolio;